"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import Img1 from "../../../../assets/images/Img10-1.png";
import Img2 from "../../../../assets/images/Img10-2.png";
import Img3 from "../../../../assets/images/Img10-3.png";
import Img4 from "../../../../assets/images/Img10-4.png";
import clsx from "clsx";
import { Playfair } from "@/app/layout";

const data = [
  {
    image: Img1,
    title: " Lorem Ipsum is simply dummy text ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
  },
  {
    image: Img2,
    title: " Lorem Ipsum is simply dummy text ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
  },
  {
    image: Img3,
    title: " Lorem Ipsum is simply dummy text ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
  },
  {
    image: Img4,
    title: " Lorem Ipsum is simply dummy text ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
  },
];

export const Component10 = () => {
  return (
    <div className={css.Wrapper}>
      <div className={css.TopContent}>
        <div className={clsx(css.TopContentTitle, Playfair.className)}>
          The Secrets of Tea
        </div>
        <div className={css.TopContentDesc}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </div>
      </div>

      <div className={css.WrapperItem}>
        {data.map((item, index) => (
          <div key={index} className={css.Item}>
            <Image src={item.image} alt="" className={css.Image} />
            <div className={clsx(css.Title, Playfair.className)}>
              {item.title}
            </div>
            <div className={css.Desc}>{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
