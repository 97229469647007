"use client";

import React from "react";
import Image from "next/image";
import { clsx } from "clsx";
import { Playfair, montserrat } from "@/app/layout";
import css from "./FloatTextImageRightLeft.module.scss";
import { TCommonFloatImageProp } from "../commonComponents/type";
import { useTranslation } from "react-i18next";
export const FloatTextImageRightLeft = (props: TCommonFloatImageProp) => {
  const {
    title,
    desc,
    background,
    floatImage,
    isImageFloatRight,
    isTextCenter,
    isShowButton,
  } = props;
  const { t } = useTranslation("home");
  return (
    <div className={css.Wrapper}>
      <div className={css.Image}>
        {(isImageFloatRight && (
          <Image
            src={floatImage}
            alt="floatImage"
            className={css.floatImageRight}
          />
        )) || (
          <Image
            src={floatImage}
            alt="floatImage"
            className={css.floatImageLeft}
          />
        )}
        <Image
          src={background}
          alt="background"
          className={css.BackgroundImage}
        />
      </div>
      {(isTextCenter && (
        <div className={css.TextCenter}>
          <div className={clsx(css.Title, Playfair.className)}>{title}</div>
          <div className={clsx(css.Desc, montserrat.className)}>{desc}</div>
        </div>
      )) || (
        <div className={css.Text}>
          <div className={clsx(css.Title, Playfair.className)}>{title}</div>
          <div className={clsx(css.Desc, montserrat.className)}>{desc}</div>
        </div>
      )}

      {isShowButton && (
        <div className={css.BtnSeeMore}>{t("Comp2.button")}</div>
      )}
    </div>
  );
};
