"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import Logo from "../../../../assets/images/CASA.png";
import { useTranslation } from "react-i18next";
import { Form, Input, message } from "antd";
import { useState } from "react";
import { useUrl } from "nextjs-current-url";
type TFormSubmit = {
  fullName: string;
  phoneNumber: string;
  email: string;
  message: string;
};

export const LeaveMessage = () => {
  const { t } = useTranslation("home");
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const { href: currentUrl } = useUrl() ?? {};
  const getBaseUrl = (url: string) => {
    if (url) {
      const parts = url.split("/");
      const baseUrl = parts.slice(0, 3).join("/");
      return baseUrl;
    } else return "https://casateafood.com";
  };

  const showSuccess = () => {
    messageApi.open({
      type: "success",
      content: "Send email successfully",
    });
  };

  const showError = () => {
    messageApi.open({
      type: "error",
      content: "Some error occurred",
    });
  };
  const onFinish = async (values: TFormSubmit) => {
    if (loading) return;
    setLoading(true);

    try {
      const res = await fetch(
        `${getBaseUrl(currentUrl ?? "")}/api/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const responseBody = await res.json();
      if (responseBody.status !== 200) {
        showError();
        setLoading(false);

        return;
      }
      form.resetFields();
      showSuccess();
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      showError();
      setLoading(false);
    }
  };

  return (
    <div className={css.Wrapper}>
      <div className={css.Left}>
        <div className={css.Title}>{t("Leave Message.title")}</div>
        <div className={css.Divider} />

        <div className={css.Form}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={(errorInfo) => {
              console.log("Failed:", errorInfo);
            }}
            form={form}
          >
            <Form.Item
              label={t("Leave Message.content1")}
              name="fullName"
              rules={[
                { required: true, message: t("Leave Message.content1Warning") },
              ]}
            >
              <Input
                className={css.Input}
                placeholder="Full name"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              label={t("Leave Message.content2")}
              name="phoneNumber"
              rules={[
                { required: true, message: t("Leave Message.content2Warning") },
              ]}
            >
              <Input
                className={css.Input}
                placeholder="Phone number"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              label={t("Leave Message.content3")}
              name="email"
              rules={[
                { required: true, message: t("Leave Message.content3Warning") },
                { type: "email", message: t("Leave Message.content3Invalid") },
              ]}
            >
              <Input
                className={css.Input}
                placeholder="Email"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              label={t("Leave Message.content4")}
              name="message"
              rules={[
                { required: true, message: t("Leave Message.content4Warning") },
              ]}
            >
              <Input.TextArea
                className={css.Input}
                placeholder="Your message"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item>
              <div className={css.BtnWrapper}>
                <button
                  type="submit"
                  className={css.BtnSubmit}
                  disabled={loading}
                >
                  {loading ? "Sending..." : t("Leave Message.button")}
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={css.Right}>
        <div className={css.WrapperLogo}>
          <Image src={Logo} alt="Casatea" className={css.Logo} />
        </div>
        <div className={css.DividerRight} />
        <div className={css.RightInfo}>{t("Leave Message.labelDesc")}</div>
      </div>

      {contextHolder}
    </div>
  );
};
