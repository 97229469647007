import Image from "next/image";
import React, { FC } from "react";
import css from "./productItem.module.scss";
import { IProductItemProps } from "./type";
import clsx from "clsx";
import { Playfair } from "@/app/layout";
import ColoredStar from "../../assets/icons/Star ON.png";
import Star from "../../assets/icons/Star OFF.png";
import NoImage from "../../assets/images/No_Image_Available.jpg";
import { formatCurrency } from "@/utils/common";
import Link from "next/link";

const ProductItem: FC<IProductItemProps> = ({
  id,
  src,
  price,
  rating,
  title,
  wrapperClassName,
}) => {
  return (
    <div className={clsx(css.Wrapper, wrapperClassName)}>
      <div className={css.ProductImageWrapper}>
        <Image
          width={200}
          height={200}
          src={src ?? NoImage}
          alt="Tea bag"
          className={css.ProductImage}
          sizes="100vw"
          quality={100}
        />
      </div>
      <div className={css.MainContent}>
        <div className={clsx(css.Title, Playfair.className)}>{title}</div>
        <div className={css.StarsWrapper}>
          {[...Array(rating)].map((_, index) => (
            <Image
              width={20}
              height={20}
              key={index}
              src={ColoredStar}
              alt="colored star"
              className={css.Star}
            />
          ))}
          {[...Array(5 - rating)].map((_, index) => (
            <Image key={index} src={Star} alt="star" className={css.Star} />
          ))}
        </div>
      </div>

      <div className={css.Footer}>
        <div className={css.Price}>{formatCurrency(price ? +price : 0)}</div>
        <Link
          prefetch={false}
          className={css.More}
          href={`/productDetail?id=${id}`}
        >
          Xem thêm
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
