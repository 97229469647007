"use client";
import {
  Component1,
  LeaveMessage,
  Component2,
  Component3,
  Component4,
  Component5,
  Component7,
  Component8,
  Component9,
} from "@/components/pages/home";
import { MainProductsList } from "@/components/pages/mainProducts/mainProductsList";
import React from "react";
import "../i18n/i18n";

export default function Home() {
  return (
    <main>
      <Component1 />
      <Component2 />
      <Component3 />
      <Component4 />
      <Component5 />
      <MainProductsList isShowAll={false} />
      <Component7 />
      <Component8 />
      <Component9 />
      <LeaveMessage />
    </main>
  );
}
