"use client";

import BackgroundImage from "../../../../../public/misty-morning-strawberry-plant-doi-ang-khang-mountain-chiang-mai-thailand bg 1.png";
import Baobi1 from "../../../../assets/images/baobi11.png";
import Baobi3 from "../../../../assets/images/baobi31.png";
import Image from "next/image";
import css from "./styles.module.scss";
import clsx from "clsx";
import { Playfair } from "@/app/layout";
import { useTranslation } from "react-i18next";
import Link from "next/link";

export const Component1 = () => {
  const { t } = useTranslation("home");
  return (
    <div className={css.WrapperAll}>
      <div className={css.WrapperDesktop}>
        <div className={css.TextWrapper}>
          <div className={clsx(css.Content1, Playfair.className)}>
            {t("Comp1.title")}
          </div>

          <div className={clsx(css.Content3, Playfair.className)}>
            {t("Comp1.subTitle")}
          </div>

          <div className={clsx(css.Content2, Playfair.className)}>
            {t("Comp1.content")}
          </div>
        </div>
        <Link href="/mainProducts">
          <div className={css.Button}>Buy Now</div>
        </Link>
        <Image src={Baobi1} alt="Baobi1" className={css.Baobi1} />

        <Image src={Baobi3} alt="Baobi3" className={css.Baobi3} />
        <Image
          src={BackgroundImage}
          alt="background"
          className={css.BackgroundImage}
        />
      </div>

      <div className={css.WrapperMobile}>
        <div className={css.TextWrapperMobile}>
          <div className={clsx(css.Content1Mobile, Playfair.className)}>
            {t("Comp1.title")}
          </div>

          <div className={css.Content3Mobile}>{t("Comp1.subTitle")}</div>

          <div className={css.Content2Mobile}>{t("Comp1.content")}</div>
        </div>
        <Link href="/mainProducts">
          <div className={css.ButtonMobile}>Buy Now</div>
        </Link>
        <Image src={Baobi1} alt="Baobi1" className={css.Baobi1Mobile} />

        <Image src={Baobi3} alt="Baobi3" className={css.Baobi3Mobile} />
        <Image
          src={BackgroundImage}
          alt="background"
          className={css.BackgroundImageMobile}
        />
      </div>
    </div>
  );
};
