import { Playfair_Display, Montserrat } from "next/font/google";
import { ConfigProvider } from "antd";
import { customAntdTheme } from "@/styles/customAntdTheme";
import "./globals.css";
import "antd/dist/reset.css";
import { AppProvider } from "@/contexts";
import localFont from "next/font/local";
import Image from "next/image";
import Messenger from "../assets/icons/messenger.svg";
import Zalo from "../assets/icons/NewZalo.svg";
import { NewHeader } from "@/components/Header";
import { Footer } from "@/components/Footer";

export const Playfair = Playfair_Display({ subsets: ["latin"] });
export const montserrat = Montserrat({ subsets: ["latin"] });
export const myFont = localFont({
  src: "./font/SVNGilroy Bold.otf",
  display: "swap",
});
export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en" className="">
      <ConfigProvider theme={customAntdTheme}>
        <AppProvider>
          <body className={montserrat.className} style={{ maxWidth: 3840 }}>
            <div
              style={{
                position: "fixed",
                zIndex: 1001,
                top: 0,
                right: 0,
                left: 0,
              }}
            >
              <NewHeader />
            </div>
            <div className="underDiv" />

            {children}
            <Footer />

            <a
              className="IconFloat1"
              href="https://zalo.me/0919132062"
              target="_blank"
            >
              <Image width={50} height={50} src={Zalo} alt="Zalo" />
            </a>

            <a
              className="IconFloat2"
              href="https://www.facebook.com/casateanfood?mibextid=LQQJ4d"
              target="_blank"
            >
              <Image width={50} height={50} src={Messenger} alt="Messenger" />
            </a>

            <a className="IconFloat2" href="/productDetail" target="_blank">
              <Image width={50} height={50} src={Messenger} alt="Messenger" />
            </a>
          </body>
        </AppProvider>
      </ConfigProvider>
    </html>
  );
}
