import { ThemeConfig } from "antd";

export const customAntdTheme: ThemeConfig = {
  token: {
    colorPrimary: "#C1463C",
    borderRadius: 0,
  },
  components: {
    Button: {
      borderRadius: 0,
    },
    Input: {
      borderRadius: 0,
    },
    Select: {
      borderRadius: 0,
    },
    Pagination: {
      borderRadius: 0,
    },
    Checkbox: {
      borderRadius: 0,
      colorPrimaryActive: "#C1463C",
    },
  },
};
