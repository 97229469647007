"use client";

import clsx from "clsx";
import css from "./styles.module.scss";
import { montserrat, Playfair } from "@/app/layout";
import ProductItem from "../../../commonComponents/productItem";
import { useContext } from "react";
import { AppContext } from "@/contexts";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
export type TProduct = {
  currency: string;
  id: string;
  price: number;
  name: string;
  thumbnail: string;
  description: string;
  quantity: number;
  status: string;
  rating?: number;
  images?: string[];
  metadata: {
    thanhPhan: string;
    huongDanSuDung: string;
    quyCachDongGoi: string;
  };
};
export const MainProductsList = ({ isShowAll }: { isShowAll: boolean }) => {
  const contextValue = useContext(AppContext);
  const { t } = useTranslation("home");
  const { products, isLoading } = contextValue;

  const data = isShowAll ? products : products.slice(0, 6);
  return isLoading ? (
    <Spin />
  ) : (
    <div className={css.Container}>
      <div className={css.HeaderWrapper}>
        <h2 className={clsx(css.Title, Playfair.className)}>
          {t("Comp6.title")}
        </h2>
        <p className={clsx(css.Desc, montserrat.className)}>
          {t("Comp6.content")}
        </p>
      </div>

      <div className={css.ProductsList}>
        {data.map((p) => {
          return (
            <ProductItem
              src={p.thumbnail}
              title={p.name}
              desc={p.description}
              key={p.id}
              id={p.id}
              price={p.price}
              rating={p.rating ?? 5}
              wrapperClassName={css.Product}
            />
          );
        })}
      </div>
    </div>
  );
};
