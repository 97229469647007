/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from "lodash";
/**
 * The function `sleep` is a TypeScript function that returns a promise that resolves after a specified
 * number of milliseconds.
 * @param {number} ms - The `ms` parameter is the number of milliseconds to wait before resolving the
 * promise.
 */
export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * The `debounce` function is a TypeScript implementation of a debounce function that delays the
 * execution of a function until a certain amount of time has passed since the last time it was called.
 * @param {Function} func - The `func` parameter is the function that you want to debounce. It is the
 * function that will be called after the debounce delay has passed.
 * @param {number} [delay=400] - The `delay` parameter is the amount of time in milliseconds that the
 * function should wait before executing after the last invocation. By default, it is set to 400
 * milliseconds.
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const debounce = (func: Function, delay: number = 400) => {
  let timmerId: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    if (timmerId) {
      clearTimeout(timmerId);
    }
    timmerId = setTimeout(() => func(args), delay);
  };
};

/**
 * The above function generates a random UUID (Universally Unique Identifier) in TypeScript.
 */
export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};

/**
 * The `safelyJsonParse` function attempts to parse a string into a JSON object and returns the parsed
 * object if successful, otherwise it logs an error and returns undefined.
 * @param {string} json - The `str` parameter is a string that represents a JSON object.
 */
export const safelyJsonParse = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (err) {
    console.error({ err });

    return;
  }
};

/**
 * The `buildQueryString` function takes in various types of query parameters and returns a string
 * representation of the URL query string.
 * @param {| string
 *     | Record<string, string>
 *     | string[][]
 *     | URLSearchParams
 *     | undefined} queryParams - The `queryParams` parameter can be one of the following types:
 */
export const buildQueryString = (
  queryParams:
    | string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Record<string, any>
    | string[][]
    | URLSearchParams
    | undefined,
): string => {
  if (isEmpty(queryParams)) {
    return "";
  }

  return new URLSearchParams(queryParams).toString();
};

export const formatCurrency = (price = 0) => {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(price);
};
