"use client";
import css from "./styles.module.scss";
import { Playfair } from "@/app/layout";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const Component3 = () => {
  const { t } = useTranslation("home");
  const data = [
    {
      number: "20",
      text: t("Comp3.desc1"),
    },
    {
      number: "22",
      text: t("Comp3.desc2"),
    },
    {
      number: "5+",
      text: t("Comp3.desc3"),
    },
    {
      number: "10+",
      text: t("Comp3.desc4"),
    },
  ];

  return (
    <>
      <div className={css.Wrapper}>
        <div className={css.Content}>
          <div className={css.Infos}>
            {data.map((item, index) => (
              <div key={index} className={css.Info}>
                <span className={clsx(css.InfoNumber, Playfair.className)}>
                  {" "}
                  {item.number}
                </span>
                <span className={css.InfoText}>{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
