"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import Img1 from "../../../../assets/images/ImgHome9-1.png";
import Img2 from "../../../../assets/images/ImgHome9-2.png";
import Img3 from "../../../../assets/images/ImgHome9-3.png";
import Img4 from "../../../../assets/images/ImgHome9-4.png";
import Img5 from "../../../../assets/images/teapot.png";
import Img6 from "../../../../assets/images/ImgHome9-6.png";
import Img7 from "../../../../assets/images/ImgHome9-7.png";
import Img8 from "../../../../assets/images/ImgHome9-8.png";
import clsx from "clsx";
import { Playfair } from "@/app/layout";
import { useTranslation } from "react-i18next";
export const Component9 = () => {
  const { t } = useTranslation("home");
  const data = [
    {
      image: Img1,
      title: t("Comp9.titlePic1"),
      desc: t("Comp9.contentPic1"),
    },
    {
      image: Img2,
      title: t("Comp9.titlePic2"),
      desc: t("Comp9.contentPic2"),
    },
    {
      image: Img3,
      title: t("Comp9.titlePic3"),
      desc: t("Comp9.contentPic3"),
    },
    {
      image: Img4,
      title: t("Comp9.titlePic4"),
      desc: t("Comp9.contentPic4"),
    },
    {
      image: Img5,
      title: t("Comp9.titlePic5"),
      desc: t("Comp9.contentPic5"),
    },
    {
      image: Img6,
      title: t("Comp9.titlePic6"),
      desc: t("Comp9.contentPic6"),
    },
    {
      image: Img7,
      title: t("Comp9.titlePic7"),
      desc: t("Comp9.contentPic7"),
    },
    {
      image: Img8,
      title: t("Comp9.titlePic8"),
      desc: t("Comp9.contentPic8"),
    },
  ];

  return (
    <div className={css.Wrapper}>
      <div className={css.TopContent}>
        <div className={clsx(css.TopContentTitle, Playfair.className)}>
          {t("Comp9.title")}
        </div>
        <div className={css.TopContentDesc}>{t("Comp9.content")}</div>
      </div>

      <div className={css.ContentWrapper}>
        {data.map((item, index) => {
          return (
            <div key={index} className={css.Content}>
              <Image
                src={item.image}
                alt="Background"
                className={css.ContentImage}
              />
              <div className={clsx(css.ContentTitle, Playfair.className)}>
                {item.title}
              </div>
              <div className={css.ContentDesc}>{item.desc}</div>
            </div>
          );
        })}
      </div>

      <div className={css.BtnSeeMore}>{t("Comp9.button")}</div>
    </div>
  );
};
