"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import clsx from "clsx";
import Image1 from "../../../../assets/images/ImgBGHome7-1.png";
import Image2 from "../../../../assets/images/Imgae2-7.png";
import Image3 from "../../../../assets/images/ImgBGHome7-2.png";
import Image4 from "../../../../assets/images/ImgBGHome7-3.png";
import { Playfair } from "@/app/layout";
import { FloatTextNumber } from "@/components/commonComponentsMobile/FloatTextNumber";
import { useTranslation } from "react-i18next";
export const Component7 = () => {
  const { t } = useTranslation("home");
  const dataHeader = {
    title: t("Comp7.title"),
    desc: t("Comp7.content"),
  };

  const data = [
    {
      image: Image1,
      infoHeader: t("Comp7.titlePic1"),
      infoDesc: t("Comp7.contentPic1"),
    },
    {
      image: Image2,
      infoHeader: t("Comp7.titlePic2"),
      infoDesc: t("Comp7.contentPic2"),
    },
    {
      image: Image3,
      infoHeader: t("Comp7.titlePic3"),
      infoDesc: t("Comp7.contentPic3"),
    },
    {
      image: Image4,
      infoHeader: t("Comp7.titlePic4"),
      infoDesc: t("Comp7.contentPic4"),
    },
  ];

  return (
    <>
      <div className={clsx(css.Wrapper, css.DesktopResponsive)}>
        <div className={css.TopContent}>
          <div className={clsx(css.TopContentTitle, Playfair.className)}>
            {t("Comp7.title")}
          </div>
          <div className={css.TopContentDesc}>{t("Comp7.content")}</div>
        </div>

        <div className={css.InfoWrapper}>
          <div className={css.InfoWrapperItem}>
            <Image
              src={Image1}
              alt="background"
              className={css.InfoWrapperItemImg}
            />

            <div className={clsx(css.ItemMiddle, Playfair.className)}>1</div>
            <div className={css.ItemRight}>
              <div className={clsx(css.ItemRightTitle, Playfair.className)}>
                {t("Comp7.titlePic1")}
              </div>
              <div className={css.ItemRightDesc}>{t("Comp7.contentPic1")}</div>
            </div>
          </div>

          <div className={css.InfoWrapperItem}>
            <div className={css.ItemRight}>
              <div className={clsx(css.ItemRightTitle, Playfair.className)}>
                {t("Comp7.titlePic2")}
              </div>
              <div className={css.ItemRightDesc}>{t("Comp7.contentPic2")}</div>
            </div>
            <div className={clsx(css.ItemMiddle, Playfair.className)}>2</div>

            <Image
              src={Image2}
              alt="background"
              className={css.InfoWrapperItemImg}
            />
          </div>

          <div className={css.InfoWrapperItem}>
            <Image
              src={Image3}
              alt="background"
              className={css.InfoWrapperItemImg}
            />

            <div className={clsx(css.ItemMiddle, Playfair.className)}>3</div>
            <div className={css.ItemRight}>
              <div className={clsx(css.ItemRightTitle, Playfair.className)}>
                {t("Comp7.titlePic3")}
              </div>
              <div className={css.ItemRightDesc}>{t("Comp7.contentPic3")}</div>
            </div>
          </div>

          <div className={css.InfoWrapperItem}>
            <div className={css.ItemRight}>
              <div className={clsx(css.ItemRightTitle, Playfair.className)}>
                {t("Comp7.titlePic4")}
              </div>
              <div className={css.ItemRightDesc}>{t("Comp7.contentPic4")}</div>
            </div>
            <div className={clsx(css.ItemMiddle, Playfair.className)}>4</div>
            <Image
              src={Image4}
              alt="background"
              className={css.InfoWrapperItemImg}
            />
          </div>
        </div>
      </div>
      <div className={css.MobileResponsive}>
        <FloatTextNumber isShowHeader dataHeader={dataHeader} data={data} />
      </div>
    </>
  );
};
