"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import Background from "../../../../assets/images/Background6.png";

import clsx from "clsx";
import { Playfair } from "@/app/layout";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/contexts";
import ProductItem from "@/components/commonComponents/productItem";

export const Component6 = () => {
  const [width, setWidth] = useState(0);
  const contextValue = useContext(AppContext);

  useEffect(() => {
    const parent = document.getElementById("parent");
    const child1 = document.getElementById("child1");
    const child2 = document.getElementById("child2");

    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.addEventListener("load", () => setWidth(window.innerWidth));
    if (parent && child1 && child2) {
      parent.style.height = `${child1.offsetHeight + child2.offsetHeight - 200}px`;
    }

    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
      window.removeEventListener("load", () => setWidth(window.innerWidth));
    };
  }, [width]);

  return (
    <div id="parent" className={css.Wrapper}>
      <div id="child1">
        <Image src={Background} alt="Background" className={css.Background} />
        <div className={css.Content}>
          <div className={clsx(css.ContentLeft, Playfair.className)}>
            Top 6 Best-Selling Products
          </div>
          <div className={css.ContentRight}>
            Come learn about our best-selling items! Selected six of the
            best-selling products, you must not miss it!
          </div>
        </div>
      </div>

      <div id="child2" className={css.Products}>
        <div className={css.ProductDetail}>
          {contextValue.products.slice(0, 6).map((p) => {
            return (
              <ProductItem
                src={p.thumbnail}
                title={p.name}
                desc={p.description}
                key={p.id}
                id={p.id}
                price={p.price}
                rating={5}
                wrapperClassName={css.Product}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
