import React from "react";
import Image from "next/image";
import { clsx } from "clsx";
import { Playfair, montserrat } from "@/app/layout";
import css from "./NormalTextImage.module.scss";
import { TCommonTDCompPropsNoBackground } from "../commonComponents/type";
import { useTranslation } from "react-i18next";
export const NormalTextImage = (props: TCommonTDCompPropsNoBackground) => {
  const { data, isShowHeader, dataHeader, isShowButton, isShowNumber } = props;
  const { t } = useTranslation("home");
  return (
    <div className={css.Wrapper}>
      {isShowHeader && (
        <>
          <div className={css.TopContent}>
            <div className={clsx(css.TopContentTitle, Playfair.className)}>
              <span className={css.TextInfo1}>{dataHeader?.title}</span>
            </div>
            <div className={clsx(css.TopContentDesc, montserrat.className)}>
              {dataHeader?.desc}
            </div>
          </div>
        </>
      )}
      <div className={css.ContentWrapper}>
        {data.map((item, index) => {
          return (
            <div key={index} className={css.Content}>
              <div className={css.ImageWrapper}>
                {isShowNumber && (
                  <div className={clsx(css.Number, Playfair.className)}>
                    0{index + 1}
                  </div>
                )}
                <Image
                  src={item.image}
                  alt="Background"
                  className={css.ContentImage}
                />
              </div>
              <div className={clsx(css.ContentTitle, Playfair.className)}>
                {item.infoHeader}
              </div>
              <div className={clsx(css.ContentDesc, montserrat.className)}>
                {item.infoDesc}
              </div>
            </div>
          );
        })}
      </div>
      {isShowButton && (
        <div className={css.BtnSeeMore}>{t("Comp2.button")}</div>
      )}
    </div>
  );
};
