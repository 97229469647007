import React from "react";
import Image from "next/image";
import { clsx } from "clsx";
import { Playfair } from "@/app/layout";
import css from "./FloatTextNumber.module.scss";
import { TCommonFirstCompPropWithHeader } from "../commonComponents/type";

export const FloatTextNumber = (props: TCommonFirstCompPropWithHeader) => {
  const { data, isShowHeader, dataHeader } = props;
  return (
    <div className={css.Wrapper}>
      {isShowHeader && (
        <>
          <div className={css.TopContent}>
            <div className={clsx(css.TopContentTitle, Playfair.className)}>
              {dataHeader?.title}
            </div>
            <div className={css.TopContentDesc}>{dataHeader?.desc}</div>
          </div>
        </>
      )}
      <div className={css.Content}>
        <div className={css.Infos}>
          {data.map((item, index) => (
            <div key={index} className={css.Info}>
              <div className={css.Parent}>
                <Image className={css.Icon} src={item.image} alt="Icon" />
                <div className={clsx(css.floatNumber, Playfair.className)}>
                  {index + 1}
                </div>
              </div>
              <div className={clsx(css.InfoNumber, Playfair.className)}>
                {item.infoHeader}
              </div>
              <div className={css.InfoText}>{item.infoDesc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
