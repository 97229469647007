"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import Img1 from "../../../../assets/images/Img8-1.png";
import Img2 from "../../../../assets/images/Img8-2.png";
import Img3 from "../../../../assets/images/Img8-3.png";
import Img4 from "../../../../assets/images/Img8-4.png";
import Img5 from "../../../../assets/images/Img8-5.png";
import Img6 from "../../../../assets/images/Img8-6.png";
import Img7 from "../../../../assets/images/Img8-7.png";
import Img8 from "../../../../assets/images/Img8-8.png";
import Img9 from "../../../../assets/images/Img8-9.png";
import Img10 from "../../../../assets/images/Img8-10.png";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Playfair } from "@/app/layout";
export const Component8 = () => {
  const { t } = useTranslation("home");
  const images = [
    {
      src: Img1,
      text: t("Comp8.icon1"),
    },
    {
      src: Img2,
      text: t("Comp8.icon2"),
    },
    {
      src: Img3,
      text: t("Comp8.icon3"),
    },
    {
      src: Img4,
      text: t("Comp8.icon4"),
    },
    {
      src: Img5,
      text: t("Comp8.icon5"),
    },
    {
      src: Img6,
      text: t("Comp8.icon6"),
    },
    {
      src: Img7,
      text: t("Comp8.icon7"),
    },
    {
      src: Img8,
      text: t("Comp8.icon8"),
    },
    {
      src: Img9,
      text: t("Comp8.icon9"),
    },
    {
      src: Img10,
      text: t("Comp8.icon10"),
    },
  ];

  return (
    <div className={css.Wrapper}>
      <div className={css.Left}>
        <div className={clsx(css.Title, Playfair.className)}>
          {t("Comp8.title")}
        </div>
        <div className={css.Desc}>{t("Comp8.content")}</div>
      </div>

      <div className={css.Right}>
        {images.map((image, index) => {
          let pElement;
          if (image.text.includes("/")) {
            const parts = image.text.split("/");
            pElement = (
              <p>
                {parts[0]}/<br />
                {parts[1]}
              </p>
            );
          } else {
            pElement = <p>{image.text}</p>;
          }
          return (
            <div key={index} className={css.Box}>
              <Image
                width={100}
                height={100}
                src={image.src}
                alt="Image"
                className={clsx(css.Image)}
              />
              <div className={css.SubText}>{pElement}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
