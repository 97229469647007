import Pic1 from "../../../../assets/images/ImgBGHome7-1.png";
import Pic2 from "../../../../assets/images/elevated-view-female-hand-making-turkish-coffee-sand-caf 1.png";
import Pic3 from "../../../../assets/images/elevated-view-female-hand-making-turkish-coffee-sand-caf 3.png";
import { InfoCommonSecondCompProp } from "@/components/commonComponents/type";
import { NormalTextImage } from "@/components/commonComponentsMobile/NormalTextImage";
import CommonSecondComponent from "@/components/commonComponents/CommonSecondComponent";
import css from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export const Component2 = () => {
  const { t } = useTranslation("home");
  const dataHeader = {
    title: t("Comp2.title"),
    desc: t("Comp2.content"),
  };
  const data: InfoCommonSecondCompProp[] = [
    {
      infoHeader: t("Comp2.titlePic1"),
      infoDesc: t("Comp2.contentPic1"),
      image: Pic1,
    },
    {
      infoHeader: t("Comp2.titlePic2"),
      infoDesc: t("Comp2.contentPic2"),
      image: Pic2,
    },
    {
      infoHeader: t("Comp2.titlePic3"),
      infoDesc: t("Comp2.contentPic3"),
      image: Pic3,
    },
  ];
  return (
    <div>
      <div className={css.DesktopResponve}>
        <CommonSecondComponent
          isShowButton
          isShowHeader
          data={data}
          dataHeader={dataHeader}
        />
      </div>

      <div className={css.MobileResponsive}>
        <NormalTextImage
          data={data}
          isShowHeader
          isShowButton
          isShowNumber={false}
        />
      </div>
    </div>
  );
};
