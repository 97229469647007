"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import Background from "../../../../assets/images/ImageComponent4.png";
import clsx from "clsx";
import { Playfair } from "@/app/layout";
import floatImage from "../../../../assets/images/KhayTS.png";
import { FloatTextImageRightLeft } from "@/components/commonComponentsMobile/FloatTextImageRightLeft";
import { useTranslation } from "react-i18next";

export const Component4 = () => {
  const { t } = useTranslation("home");
  return (
    <>
      <div className={clsx(css.Wrapper, css.DesktopResponsive)}>
        <div className={css.Left}>
          <Image className={css.Background} src={Background} alt="Background" />
          <Image className={css.floatImage} src={floatImage} alt="KhayTS" />
        </div>
        <div className={css.Content}>
          <div className={clsx(css.CotentTitle, Playfair.className)}>
            {t("Comp4.title")}
          </div>

          <div className={css.CotentDesc}>{t("Comp4.content")}</div>

          <div className={css.BtnSeeMore}>{t("Comp4.button")}</div>
        </div>
      </div>
      <div className={css.MobileResponsive}>
        <FloatTextImageRightLeft
          title={t("Comp4.title")}
          desc={t("Comp4.content")}
          background={Background}
          floatImage={floatImage}
          isTextCenter={false}
          isShowButton
          isImageFloatRight
        />
      </div>
    </>
  );
};
