"use client";
import React from "react";
import css from "./CommonSecondComponent.module.scss";
import Image from "next/image";
import { clsx } from "clsx";
import { Playfair } from "@/app/layout";
import Tea1 from "../../assets/images/Tea1.svg";
import Tea2 from "../../assets/images/Tea2.svg";
import { TCommonTDCompPropsNoBackground } from "./type";
import { useTranslation } from "react-i18next";

const CommonSecondComponent = (props: TCommonTDCompPropsNoBackground) => {
  const { data, isShowHeader, isShowButton, dataHeader } = props;
  const { t } = useTranslation("home");
  return (
    <div className={css.Wrapper}>
      {isShowHeader && (
        <>
          <Image src={Tea1} alt="Tea1" className={css.Tea1} />
          <Image src={Tea2} alt="Tea1" className={css.Tea2} />
          <div className={css.Content}>
            <div className={clsx(css.ContentInfo1, Playfair.className)}>
              <span className={css.TextInfo1}>{dataHeader?.title}</span>
            </div>
            <div className={css.ContentInfo2}>{dataHeader?.desc}</div>
          </div>
        </>
      )}

      <div className={css.Content2}>
        {data.map((item, index) => {
          return (
            <div key={index} className={css.Content2Tag1}>
              {index % 2 === 0 && (
                <Image src={item.image} alt="Tea1" className={css.Pic1} />
              )}
              <div className={css.Content2Tag1Text}>
                <div
                  className={clsx(
                    css.Content2Tag1TextTitle,
                    Playfair.className,
                  )}
                >
                  {item.infoHeader}
                </div>
                <div className={css.TextInfo2}>{item.infoDesc}</div>
              </div>

              {index % 2 === 1 && (
                <Image src={item.image} alt="Tea1" className={css.Pic1} />
              )}
            </div>
          );
        })}

        {isShowButton && (
          <div className={css.BtnSeeMore}>{t("Comp2.button")}</div>
        )}
      </div>
    </div>
  );
};

export default CommonSecondComponent;
